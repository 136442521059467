@import 'styles/variables.scss';

.main {
  display: block;
  width: 100%;
  max-width: 90%;


}
@media only screen and (max-width: $smallDevice) {
  .main {
    max-width: unset;
  }
}
