.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #e0e0e0;
  font-size: 75%;
  display: flex;
  justify-content: space-between;

  .item {
    margin: 0 0.5em;
  }
}
