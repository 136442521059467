.backtop {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  background-color: rgba(0,0,0,.45);
  border-radius: 1.6em;
  display: flex;
  align-items: center;
  justify-content: center;
}
